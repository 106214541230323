import React from 'react';
import {Box, BoxProps, Card, CardContent, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export type EmptyListProps = {
  text?: string;
  children?: React.ReactNode;
} & BoxProps;

const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 48,
    width: '100%',
    borderRadius: 4,
  },
  text: {
    opacity: '56%',
    fontWeight: 500,
    textAlign: 'center',
  },
}));

export const EmptyList = (props: EmptyListProps) => {
  const styles = useStyles();
  const {sx, ...rest} = props;
  return (
    <Box {...rest} className={styles.cardContainer} sx={sx}>
      <Card className={styles.card} elevation={0}>
        <CardContent>
          <Typography className={styles.text} variant="h4">
            {props.text}
          </Typography>
          {props.children}
        </CardContent>
      </Card>
    </Box>
  );
};
