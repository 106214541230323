export default function ToggleButton() {
  return {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          gap: 1,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
          padding: '6px 16px',
          border: '1px solid',
          borderColor: 'rgba(0, 0, 0, 0.12)',
          color: 'text.secondary',
          fontWeight: 500,
          fontSize: '0.875rem',
          lineHeight: 1.75,
          minHeight: 36,
          transition: 'all 0.2s ease-in-out',

          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },

          '&.Mui-selected': {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            borderColor: 'primary.main',
            fontWeight: 600,

            '&:hover': {
              backgroundColor: 'primary.dark',
              borderColor: 'primary.dark',
            },
          },

          '&.Mui-disabled': {
            backgroundColor: 'action.disabledBackground',
            borderColor: 'action.disabled',
            color: 'action.disabled',
          },

          '& .MuiCircularProgress-root': {
            marginLeft: 1,
            width: '16px !important',
            height: '16px !important',
          },

          '& .flag': {
            marginRight: 1,
            display: 'inline-flex',
            alignItems: 'center',
          },
        },
        sizeSmall: {
          padding: '4px 12px',
          fontSize: '0.8125rem',
          minHeight: 32,
        },
      },
    },
  };
}
