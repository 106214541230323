import {Badge, BadgeProps, Tooltip} from '@mui/material';

type GenericSidebarItemBadgeProps = {
  children: React.ReactNode;
} & BadgeProps;

export type GenericSidebarItemBadgeComponent =
  React.ComponentType<GenericSidebarItemBadgeProps>;

type Props = GenericSidebarItemBadgeProps & {
  tooltipContent: string;
};

export const SidebarItemBadge = ({tooltipContent, ...props}: Props) => {
  return (
    <Tooltip title={tooltipContent} placement="bottom" arrow>
      <Badge
        color="secondary"
        badgeContent={props.badgeContent}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        overlap="circular"
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: 'error.main',
            padding: '1px',
            transform: 'scale(0.75) translate(-50%, -50%)',
            left: '-1px',
            top: '-1px',
          },
        }}
        {...props}
      >
        {props.children}
      </Badge>
    </Tooltip>
  );
};
