import {Cancel} from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Chip,
  CircularProgress,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {SyntheticEvent, useState} from 'react';

export type SearchSelectValueSingle<T = string> = {
  value: T;
  label: string;
  icon?: React.ReactElement;
};
export type SearchSelectValue<T = string> = SearchSelectValueSingle<T>[];

interface SearchSelectProps<T = string>
  //@ts-ignore
  extends Omit<AutocompleteProps, 'value'> {
  options: SearchSelectValue<T>;
  label: string;
  value: SearchSelectValue<T>;
  setValue: (value: SearchSelectValue<T>) => void;
  onSearch?: (value: string) => void;
  onListScroll?: (e: SyntheticEvent) => void;
  multiple?: boolean;
  fullWidth?: boolean;
  getOptionDisabled?: (option: SearchSelectValue<T>[0]) => boolean;
  disabled?: boolean;
  error?: boolean;
  loading?: boolean;
  disabledTooltip?: string;
  minWidth?: number;
}

export const SearchSelect = <T,>({
  disabledTooltip,
  setValue,
  ...props
}: SearchSelectProps<T>) => {
  const [isFocused, setIsFocused] = useState(false);
  const [search, setSearch] = React.useState<string | undefined>();

  const options = props.options.filter(option =>
    option.label.toLowerCase().includes(search?.toLowerCase() || ''),
  );

  return (
    <Autocomplete
      {...props}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      sx={{
        '& .MuiInputBase-root': isFocused
          ? {
              height: 'auto',
              overflow: 'scroll',
            }
          : {
              height: '40px',
              overflow: 'hidden',
            },
        minWidth: props.minWidth ? props.minWidth : 175,
      }}
      fullWidth={props.fullWidth}
      id={props.label}
      size="small"
      multiple
      limitTags={1}
      options={options}
      value={props.value}
      onChange={(e, value) => {
        if (props.multiple) {
          setValue(value as SearchSelectValue<T>);
        } else {
          if (value.length > 0) {
            const lastValue = value[value.length - 1];
            setValue([lastValue]);
          } else {
            setValue([]);
          }
        }
      }}
      disabled={props.disabled}
      getOptionDisabled={props.getOptionDisabled}
      renderOption={(optionProps, option) => {
        const isDisabled = optionProps['aria-disabled'];
        return (
          <Tooltip title={isDisabled && disabledTooltip} placement="bottom">
            <Box key={`${option.value}`}>
              <MenuItem {...optionProps}>
                {option.icon}
                <Typography sx={{ml: option.icon ? 1 : 0}}>
                  {option.label}
                </Typography>
              </MenuItem>
            </Box>
          </Tooltip>
        );
      }}
      disableClearable={!props.multiple}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={option => option.label}
      renderInput={params => {
        params.inputProps.value = search;
        params.InputProps.endAdornment = props.loading ? (
          <CircularProgress sx={{position: 'absolute', right: 12}} size={20} />
        ) : (
          params.InputProps.endAdornment
        );
        return (
          <TextField
            onChange={e => {
              const newInputValue = e.target.value;
              setSearch(newInputValue);
              props.onSearch?.(newInputValue);
            }}
            onKeyDown={event => {
              if (event.key === 'Backspace') {
                event.stopPropagation();
              }
            }}
            error={props.error}
            {...params}
            label={props.label}
            sx={{marginBottom: '4px'}}
          />
        );
      }}
      ListboxProps={{
        style: {
          maxHeight: '300px',
        },
        onScroll: props.onListScroll,
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            key={option.label}
            label={option.label}
            icon={option.icon}
            onDelete={() => {
              setValue(
                props.value.filter(value => value.value !== option.value),
              );
            }}
            deleteIcon={<Cancel />}
            sx={{
              py: 0,
              mr: 0.5,
              height: 28,
              '& .MuiChip-icon': {
                marginLeft: '8px',
                marginRight: '-6px',
                padding: 0,
              },
              '& .MuiChip-deleteIcon': {
                fontSize: 16,
              },
            }}
          />
        ))
      }
    />
  );
};
